import { formatCurrency } from '../../../helpers/formatCurrency'

export function clearCurrencyToFloat(value: string, prefix = 'R$') {
  let str = value.replace(`${prefix} `, '')
  str = str.replace(/\./g, '')
  str = str.replace(/\,/g, '.')
  return str ? parseFloat(str) : 0
}

interface MinAndMaxAmountDescriptionParamsProps {
  amount: number
  minAmount: number
  maxAmount: number
  currencyLocale: 'pt-BR' | 'en-US'
}

export function minAndMaxAmountDescriptionParams({
  amount,
  minAmount,
  maxAmount,
  currencyLocale,
}: MinAndMaxAmountDescriptionParamsProps) {
  const minAmountToCurrency = formatCurrency(minAmount, currencyLocale)
  const maxAmountToCurrency = formatCurrency(maxAmount, currencyLocale)

  const isAmountLessThanMin = amount > 0 && amount < minAmount
  const isAmountMoreThanMax = amount > maxAmount

  let color = 'neutral.60'
  let i18nKey = 'nonError'
  let i18nParams: { minAmount?: string; maxAmount?: string } = {
    minAmount: minAmountToCurrency,
    maxAmount: maxAmountToCurrency,
  }

  if (isAmountLessThanMin) {
    color = 'error.60'
    i18nKey = 'errorMin'
    i18nParams = {
      minAmount: minAmountToCurrency,
    }
  }

  if (isAmountMoreThanMax) {
    color = color = 'error.60'
    i18nKey = 'errorMax'
    i18nParams = {
      maxAmount: maxAmountToCurrency,
    }
  }

  return {
    color,
    i18nKey,
    i18nParams,
  }
}
