import { useQuery, gql } from '@apollo/client'

import { Simulation } from '../types'

export const SIMULATION = gql`
  query GetSimulation($clientId: Float!) {
    salaryAdvanceSimulation(clientId: $clientId) {
      minAmountAdvanceable
      maxAmountAvailableToAdvanceToday
      fee
    }
  }
`

/**
 * @param clientId <Number> format: 34232
 */
export const useGetSimulation = (clientId: number) => {
  return useQuery<Simulation>(SIMULATION, {
    variables: { clientId },
  })
}
