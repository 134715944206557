import { css, styled } from '@creditas-ui/system'
import { getTypography, selectTheme } from '@creditas-ui/react'

const displayFlex = () => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const CalculatorBox = styled.div`
  width: 100%;

  &.error {
    .currency-input {
      color: ${selectTheme('colors.error.60')}
    }
  }

  .result-box {
    ${displayFlex}
  }

  .currency-input {
    ${getTypography('bodyXlMedium')}
    color: ${selectTheme('colors.neutral.80')};
    border: 0;
    border-bottom: 1px solid currentColor;
    text-align: center;
    max-width: 240px;
    outline: none;
    width: 100%;
  }
`

export { CalculatorBox }
