import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { QueryResult, useMutation, DocumentNode } from '@apollo/client'

import {
  Calculator,
  SalaryAdvanceBox,
  BlockDividerTitle,
  PrimaryButton,
} from '../../components/'
import { Simulation } from '../../services/bff/types'
import { formatCurrency } from '../../helpers/formatCurrency'
import { MakeSimulation } from '../../services/bff/types'
import { useGetPolicy } from '../../services/bff/queries/getPolicy'
import { Box, Flex, Typography } from '@creditas-ui/react'

interface Props {
  clientId: number
  request: (clientId: number) => QueryResult<Simulation>
  handleCallback: Function
  simulationMutation: DocumentNode
}

const SalaryAdvance = ({
  clientId,
  request,
  handleCallback,
  simulationMutation,
}: Props) => {
  const [total, setTotal] = useState(0)
  const [sending, setSending] = useState(false)
  const [totalWithoutTax, setTotalWithoutTax] = useState(0)
  const [updateSimulation] = useMutation(simulationMutation)
  const [showError, setShowError] = useState(false)
  const { t } = useTranslation()
  const { data } = request(clientId)
  const { data: policy } = useGetPolicy(clientId)

  const handleAmountTotal = (total: number) => {
    const sum = total + (data?.salaryAdvanceSimulation.fee || 0)
    setTotalWithoutTax(total)
    setTotal(sum)
  }

  const mutationBody = {
    clientId,
    requestedAmount: totalWithoutTax,
    isFromNativeApp: !!localStorage.getItem('fromNativeApp'),
  }

  const handleContinue = () => {
    setShowError(false)
    setSending(true)
    updateSimulation({ variables: mutationBody })
      .then(result => {
        const res = result.data as MakeSimulation
        const { id } = res.salaryAdvanceMakeSimulation
        handleCallback({ salaryAdvanceId: id })
      })
      .catch(() => {
        setShowError(true)
      })
      .then(() => setSending(false))
  }

  const handleError = () => (showError ? t('paragraphs.error_message') : '')

  return (
    <SalaryAdvanceBox>
      <Box>
        <BlockDividerTitle
          variant="headingSmMedium"
          color="neutral.90"
          component="h5"
        >
          {t('headings.main')}
        </BlockDividerTitle>
        {policy?.getPolicy.salaryPercentage && (
          <Typography variant="bodyMdLight" color="neutral.90">
            {t('paragraphs.main', 'policy.getPolicy')}
          </Typography>
        )}
      </Box>

      <div className="deposit-period">
        <Typography variant="bodyMdBold" color="neutral.90">
          {t('headings.how_much_advance')}
        </Typography>
        <Typography variant="bodySmLight" color="neutral.90">
          {t('paragraphs.deposit_period')}
        </Typography>
      </div>

      <Calculator
        minAmount={data?.salaryAdvanceSimulation.minAmountAdvanceable}
        maxAmount={
          data?.salaryAdvanceSimulation.maxAmountAvailableToAdvanceToday
        }
        prefixInputCurrency="R$"
        currencyLocale="pt-BR"
        cb={handleAmountTotal}
      />

      <Flex className="discount-resume">
        <div className="box">
          <Typography color="neutral.60" variant="bodyMdLight">
            {t('paragraphs.discount_next')}
          </Typography>
          <Typography color="neutral.60" variant="bodySmLight">
            {t('paragraphs.resume_discount_next')}
          </Typography>
        </div>

        <div className="box">
          <Typography color="neutral.60" variant="bodyMdLight">
            {formatCurrency(total, t('locale'))}
          </Typography>
        </div>
      </Flex>

      <PrimaryButton
        onClick={handleContinue}
        disabled={!!sending || !total}
        loading={!!sending}
      >
        {t('buttons.continue')}
      </PrimaryButton>
      <div className="error-box">{handleError()}</div>
    </SalaryAdvanceBox>
  )
}

export { SalaryAdvance }
