import { css } from '@creditas-ui/system'
import { selectTheme } from '@creditas-ui/react'

const salaryAdvanceBox = ({ theme }) => css`
  width: 100%;

  .deposit-period {
    margin: 35px 0 8px 0;
  }

  .error-box {
    margin-top: 15px;
    text-align: center;
    color: ${selectTheme('colors.error.60')({ theme })};
  }

  .discount-resume {
    justify-content: space-between;
    margin-top: 35px;
    width: 100%;
  }
`

export { salaryAdvanceBox }
