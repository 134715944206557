import React from 'react'
import tokens from '@creditas/tokens'
import { ThemeProvider } from '@creditas/stylitas'
import { ApolloProvider } from '@apollo/client'

import { SalaryAdvance } from './SalaryAdvance'
import { TranslatedErrorBoundary } from '../../components'
import { ApolloClient } from '../../services/bff/apollo-client'
import { SalaryAdvanceCustomProps } from './index.spa'
import { useGetSimulation, MAKE_SIMULATION } from '../../services/bff/queries'

class App extends React.Component<SalaryAdvanceCustomProps> {
  componentDidCatch() {}

  render() {
    const { clientId, authToken, onContinue } = this.props
    const apolloClient = ApolloClient.getAuthorizedClient(authToken)
    return (
      <TranslatedErrorBoundary>
        <ApolloProvider client={apolloClient}>
          <ThemeProvider theme={{ ...tokens }}>
            <SalaryAdvance
              clientId={clientId}
              request={useGetSimulation}
              handleCallback={onContinue}
              simulationMutation={MAKE_SIMULATION}
            />
          </ThemeProvider>
        </ApolloProvider>
      </TranslatedErrorBoundary>
    )
  }
}

export { App }
