import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import CurrencyInput from 'react-currency-input'

import { BtnAction } from './components'
import { CalculatorBox } from './calculator.style'
import {
  clearCurrencyToFloat,
  minAndMaxAmountDescriptionParams,
} from './helpers/utils'

import { Typography, TypographyColors } from '@creditas-ui/react'

interface Props {
  minAmount?: number
  maxAmount?: number
  prefixInputCurrency: string
  amountIncrement?: number
  currencyLocale: 'pt-BR' | 'en-US'
  cb: Function
}

const Calculator: React.FC<Props> = ({
  minAmount = 0,
  maxAmount = 0,
  amountIncrement = 50.0,
  prefixInputCurrency = 'R$',
  currencyLocale = 'pt-BR',
  cb,
}) => {
  const { t } = useTranslation()
  const [amount, setAmount] = useState(minAmount)
  const [errorMessage, setErrorMessage] = useState(false)

  function LimitsInformation() {
    const { color, i18nKey, i18nParams } = minAndMaxAmountDescriptionParams({
      amount,
      minAmount,
      maxAmount,
      currencyLocale,
    })

    const textMessage = t(`calculator:message.${i18nKey}`, {
      ...i18nParams,
    })

    return (
      <Typography
        data-testid="information-message"
        color={color as TypographyColors}
        variant="bodyMdLight"
        marginTop="20px"
      >
        {textMessage}
      </Typography>
    )
  }

  useEffect(() => {
    cb(minAmount)
  }, [])

  const sum = (a: number, b: number) => a + b
  const subtraction = (a: number, b: number) => a - b

  const isExceeded = (amount: number) => {
    return amount < minAmount || amount > maxAmount
  }

  const handleCalc = (callback: Function) => {
    const total = callback(amount, amountIncrement)
    if (isExceeded(total)) return
    handleTotalAmount(total)
  }

  const handleOnChange = (param: string) => {
    let total = clearCurrencyToFloat(param)
    handleTotalAmount(total)
  }

  function handleTotalAmount(value: number) {
    setAmount(value)

    if (isExceeded(value)) {
      setErrorMessage(true)
      cb(0)
      return
    }

    cb(value)
    setErrorMessage(false)
  }

  return (
    <CalculatorBox className={errorMessage ? 'error' : ''}>
      <div className="result-box">
        <BtnAction
          type="subtraction"
          className={errorMessage ? 'error' : ''}
          onClick={() => handleCalc(subtraction)}
        />

        <CurrencyInput
          value={amount}
          prefix={prefixInputCurrency + ' '}
          decimalSeparator=","
          thousandSeparator="."
          onChangeEvent={v => handleOnChange(v.target.value)}
          className="currency-input"
          autoFocus="true"
          selectAllOnFocus={true}
          data-testid="currency-input"
        />

        <BtnAction
          type="sum"
          className={errorMessage ? 'error' : ''}
          onClick={() => handleCalc(sum)}
        />
      </div>

      {LimitsInformation()}
    </CalculatorBox>
  )
}

export { Calculator }
