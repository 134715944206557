import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { QueryResult, useMutation, DocumentNode } from '@apollo/client'

import { Button } from '@creditas/button'
import { BlockDivider, BlockDividerTitle } from '@creditas/block-divider'

import {
  Calculator,
  SalaryAdvanceBox,
  BlockDividerDescription,
} from '../../components/'
import { Simulation } from '../../services/bff/types'
import { formatCurrency } from '../../helpers/formatCurrency'
import { MakeSimulation } from '../../services/bff/types'
import { useGetPolicy } from '../../services/bff/queries/getPolicy'

interface Props {
  clientId: number
  request: (clientId: number) => QueryResult<Simulation>
  handleCallback: Function
  simulationMutation: DocumentNode
}

const SalaryAdvance = ({
  clientId,
  request,
  handleCallback,
  simulationMutation,
}: Props) => {
  const [total, setTotal] = useState(0)
  const [sending, setSending] = useState(false)
  const [totalWithoutTax, setTotalWithoutTax] = useState(0)
  const [updateSimulation] = useMutation(simulationMutation)
  const [showError, setShowError] = useState(false)
  const { t } = useTranslation()
  const { data } = request(clientId)
  const { data: policy } = useGetPolicy(clientId)

  const handleAmountTotal = (total: number) => {
    const sum = total + (data?.salaryAdvanceSimulation.fee || 0)
    setTotalWithoutTax(total)
    setTotal(sum)
  }

  const mutationBody = {
    clientId,
    requestedAmount: totalWithoutTax,
    isFromNativeApp: !!localStorage.getItem('fromNativeApp'),
  }

  const handleContinue = () => {
    setShowError(false)
    setSending(true)
    updateSimulation({ variables: mutationBody })
      .then(result => {
        const res = result.data as MakeSimulation
        const { id } = res.salaryAdvanceMakeSimulation
        handleCallback({ salaryAdvanceId: id })
      })
      .catch(() => {
        setShowError(true)
      })
      .then(() => setSending(false))
  }

  const handleError = () => (showError ? t('paragraphs.error_message') : '')

  return (
    <SalaryAdvanceBox>
      <BlockDivider>
        <BlockDividerTitle>{t('headings.main')}</BlockDividerTitle>
        {policy?.getPolicy.salaryPercentage && (
          <BlockDividerDescription>
            {t('paragraphs.main', policy.getPolicy)}
          </BlockDividerDescription>
        )}
      </BlockDivider>

      <div className="deposit-period">
        <strong>{t('headings.how_much_advance')}</strong>
        <p>{t('paragraphs.deposit_period')}</p>
      </div>

      <Calculator
        minAmount={data?.salaryAdvanceSimulation.minAmountAdvanceable}
        maxAmount={
          data?.salaryAdvanceSimulation.maxAmountAvailableToAdvanceToday
        }
        prefixInputCurrency="R$"
        currencyLocale="pt-BR"
        cb={handleAmountTotal}
      />

      <div className="discount-resume">
        <div className="box">
          <p>{t('paragraphs.discount_next')}</p>
          <span>{t('paragraphs.resume_discount_next')}</span>
        </div>

        <div className="box">
          <span>{formatCurrency(total, t('locale'))}</span>
        </div>
      </div>

      <Button
        className="btn-submit"
        onClick={handleContinue}
        disabled={!!sending || !total}
        isLoading={!!sending}
      >
        {t('buttons.continue')}
      </Button>
      <div className="error-box">{handleError()}</div>
    </SalaryAdvanceBox>
  )
}

export { SalaryAdvance }
