const createTracer = (moduleName: string, action?: string): Promise<any> =>
  new Promise<void>(res => {
    if (window.newrelic) {
      window.newrelic
        .interaction()
        .createTracer(`${moduleName}${action ? '/' + action : ''}`, e =>
          res(),
        )()
    } else {
      res()
      console.warn('Your project doest not have a new relic loaded!')
    }
  })

export { createTracer }
