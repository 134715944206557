import { envVars } from '../envVars'
import { BrowserClient, Hub } from '@sentry/browser'

export class Sentry {
  static hub: Hub

  static init() {
    if (this.hub) return
    const client = new BrowserClient({
      dsn: envVars.sentryDsn,
      release: envVars.release,
      environment: envVars.environment,
      debug: envVars.environment != 'production',
    })
    this.hub = new Hub(client)
  }

  static captureException(exception: any): string {
    return this.hub.captureException(exception)
  }

  static captureMessage(message: string): string {
    return this.hub.captureMessage(message)
  }
}
