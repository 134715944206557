import { css, utilities, styled } from '@creditas/stylitas'

const fill = ({ theme }) => css`
  fill: ${utilities.palette.color(theme, 'secondary04')};
`
const fillError = ({ theme }) => css`
  fill: ${utilities.palette.color(theme, 'danger')};
`

const Button = styled.button`
  appearence: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  overflow: initial;
  align-items: center;
  justify-content: center;
  border: 0;
  background: none;
  height: 32px;
  width: 52px;
  outline: none;

  &.error svg {
    ${fillError}
  }

  svg {
    ${fill}
  }
`

export { Button }
