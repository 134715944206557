import { styled } from '@creditas-ui/system'
import { ButtonPrimaryTextOnly, getTypography } from '@creditas-ui/react'

const PrimaryButton = styled(ButtonPrimaryTextOnly)`
  margin-top: 24px;
  min-width: 100%;
  ${getTypography('bodyXlBold')}
`

export { PrimaryButton }
