import React from 'react'
import tokens from '@creditas/tokens'
import { ThemeProvider as LegacyThemeProvider } from '@creditas/stylitas'
import { ApolloProvider } from '@apollo/client'

import { SalaryAdvance } from './SalaryAdvance'
import { TranslatedErrorBoundary } from '../../components'
import { ApolloClient } from '../../services/bff/apollo-client'
import { SalaryAdvanceCustomProps } from './index.spa'
import { useGetSimulation, MAKE_SIMULATION } from '../../services/bff/queries'
import { setUserContext } from '../../utils'
import { ThemeProvider, standard } from '@creditas-ui/react'

class App extends React.Component<SalaryAdvanceCustomProps> {
  componentDidCatch() {}

  componentDidMount() {
    const { clientId } = this.props
    setUserContext({ id: clientId.toString() })
  }

  render() {
    const { clientId, authToken, onContinue } = this.props
    const apolloClient = ApolloClient.getAuthorizedClient(authToken)
    return (
      <TranslatedErrorBoundary>
        <ApolloProvider client={apolloClient}>
          <LegacyThemeProvider theme={{ ...tokens }}>
            <ThemeProvider theme={standard}>
              <SalaryAdvance
                clientId={clientId}
                request={useGetSimulation}
                handleCallback={onContinue}
                simulationMutation={MAKE_SIMULATION}
              />
            </ThemeProvider>
          </LegacyThemeProvider>
        </ApolloProvider>
      </TranslatedErrorBoundary>
    )
  }
}

export { App }
