import { gql } from '@apollo/client'

export const MAKE_SIMULATION = gql`
  mutation MakeSimulation(
    $clientId: Float!
    $requestedAmount: Float!
    $isFromNativeApp: Boolean
  ) {
    salaryAdvanceMakeSimulation(
      clientId: $clientId
      requestedAmount: $requestedAmount
      isFromNativeApp: $isFromNativeApp
    ) {
      amount
      clientId
      createdAt
      fee
      id
      status
      updatedAt
    }
  }
`
