import { styled } from '@creditas-ui/system'
import { ButtonSecondary, selectTheme } from '@creditas-ui/react'

const Button = styled(ButtonSecondary)`
  appearence: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  overflow: initial;
  align-items: center;
  justify-content: center;
  border: 0;
  background: none;
  height: 32px;
  width: 52px;
  outline: none;

  &.error svg > g > path:not(:first-child) {
    stroke: ${selectTheme('colors.error.60')};
  }

  svg > g > path:not(:first-child) {
    stroke: ${selectTheme('colors.primary.60')};
  }
`

export { Button }
