import { styled, utilities, css } from '@creditas/stylitas'
import { BlockDividerDescription as Root } from '@creditas/block-divider'

const font = ({ theme }) => css`
  ${utilities.typography.support(theme)};

  b {
    font-weight: bold;
  }
`

const BlockDividerDescription = styled(Root)`
  ${font}
`

export { BlockDividerDescription }
