import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { SalaryAdvancePolicy } from '../types'

export const POLICY = gql`
  query getPolicy($clientId: ID!) {
    getPolicy(clientId: $clientId) {
      salaryPercentage
    }
  }
`

/**
 * @param clientId <Number> format: 34232
 */
export const useGetPolicy = (clientId: number) => {
  return useQuery<SalaryAdvancePolicy>(POLICY, {
    fetchPolicy: 'no-cache',
    variables: { clientId },
  })
}
