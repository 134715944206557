import { css, utilities, styled } from '@creditas/stylitas'

const displayFlex = () => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const colorDanger = ({ theme }) => css`
  color: ${utilities.palette.color(theme, 'danger')};
`

const CalculatorBox = styled.div`
  width: 100%;

  &.error {
    .currency-input {
      ${colorDanger}
    }
  }

  .result-box {
    ${displayFlex}
  }

  .currency-input {
    ${({ theme }) => utilities.typography.paragraph(theme)};
    font-weight: 600;
    color: ${({ theme }) => utilities.palette.color(theme, 'secondary04')};
    border: 0;
    border-bottom: 1px solid currentColor;
    text-align: center;
    max-width: 240px;
    outline: none;
    width: 100%;
  }

  .min-and-max {
    ${displayFlex}
    margin-top: 8px;
    line-height: 24px;

    .box:last-child {
      text-align: right;
    }
  }
`

export { CalculatorBox }
