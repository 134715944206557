import { css, utilities } from '@creditas/stylitas'

const salaryAdvanceBox = ({ theme }) => css`
  color: ${utilities.palette.color(theme, 'neutral07')};
  width: 100%;

  .deposit-period {
    margin: 40px 0 8px 0;

    strong {
      ${utilities.typography.support(theme)}
      font-weight: 600;
    }

    p {
      ${utilities.typography.supportForm(theme)}
    }
  }

  .error-box {
    margin-top: 15px;
    text-align: center;
    color: ${utilities.palette.color(theme, 'danger')};
  }

  .discount-resume {
    ${utilities.typography.support(theme)}
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    width: 100%;

    span {
      ${utilities.typography.supportForm(theme)}
    }

    strong {
      font-weight: bold;
    }
  }

  .btn-submit {
    margin-top: 24px;
    min-width: 100%;
  }
`

export { salaryAdvanceBox }
