import React from 'react'
import { GlobalIcons } from '@creditas-ui/global-icons'
import { IconMinus_1, IconPlusAdd_3 } from '@creditas-ui/icons'
import { Button } from './btnAction.style'

interface Props {
  type: 'sum' | 'subtraction'
  onClick: Function
  className?: string
}

const BtnAction: React.FC<Props> = ({ type, onClick, className }) => {
  return (
    <Button
      data-testid={`btn-${type}`}
      onClick={() => onClick()}
      className={className}
    >
      {type === 'sum' && <GlobalIcons Icon={IconPlusAdd_3} />}

      {type === 'subtraction' && <GlobalIcons Icon={IconMinus_1} />}
    </Button>
  )
}

export { BtnAction }
